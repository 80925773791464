.footer-text {
  text-align: center;
  /* color: #868e96; */
  font-weight: thin;
  font-size: 10px;
  font-family: "Google Sans Regular";
  position: relative;
  bottom: 0px;
  width: 100%;
  height: 10px;
}

.footer-div {
  margin-top: 2rem;
}
